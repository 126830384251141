.markd {
  line-height: 2rem;
}
.markd p {
  margin: 1rem 0;
}
.markd ul,
.markd ol {
  margin-left: 2rem;
}

.markd ol li,
.markd ul li {
  list-style: disc;
  margin-left: 1rem;
  padding-left: 0.5rem;
}

.markd ol li {
  list-style: 1;
}

.markd img {
  margin: 0 auto;
}
