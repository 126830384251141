.anim-white-to-black {
  position: relative;
  color: white;
  transition: color 3s;
  overflow: hidden;
  z-index: 0;
}
*[role="none"].anim-white-to-black {
  color: black;
}
.anim-white-to-black::before {
  z-index: -1;
  content: "";
  position: absolute;
  transition: transform 3s;
  transform: translateY(-10%);
  top: 0;
  left: 0;
  height: 120%;
  width: 100%;
  background: linear-gradient(0deg, black, black 90%, transparent) no-repeat;
}
*[role="none"].anim-white-to-black::before {
  transform: translateY(100%);
}
