@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  --dimmed: rgba(255, 255, 255, 0.5);
  font-family: "Bodoni Moda", cursive, "Segoe UI", "Roboto", -apple-system,
    BlinkMacSystemFont, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  scroll-behavior: smooth;
}
.hide-scroll,
.hide-scroll > body {
  scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar,
.hide-scroll > body::-webkit-scrollbar {
  display: none;
}
.min-scroll,
.min-scroll > body {
  scrollbar-color: #888 transparent;
}
.min-scroll::-webkit-scrollbar,
.min-scroll > body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}
.min-scroll::-webkit-scrollbar-thumb,
.min-scroll > body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a:active {
  opacity: 0.8;
}
a {
  @apply text-stone-500 hover:text-black;
}

.spin-3d {
  animation: spin-3d 2s linear infinite;
}
@keyframes spin-3d {
  50% {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/*swiper widget*/

.swiper-colors {
  --swiper-theme-color: black;
  text-shadow: 0 0 2px white;
}
.swiper-colors .swiper-pagination-bullet {
  box-shadow: 0 0 1px 1px white;
}

/* animations */

.slide-in {
  animation: slide-in 1.2s ease-in-out both;
}
@keyframes slide-in {
  from {
    transform: translateY(10vh);
    opacity: 0;
  }
}
.slide-in-top {
  animation: slide-in-top 1.2s ease-in-out both;
}
@keyframes slide-in-top {
  from {
    transform: translateY(-10vh);
    opacity: 0;
  }
}
.fade-in {
  animation: fade-in 1.2s ease-in-out both;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 1.2s ease-in-out both;
}
@keyframes fade-out {
  to {
    opacity: 0;
  }
}
.zoom-in {
  animation: zoom-in 1.2s ease-in-out both;
}
@keyframes zoom-in {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
}

*[role="none"],
*[role="none"] .slide-in-top,
*[role="none"] .slide-in,
*[role="none"] .fade-out,
*[role="none"] .fade-in,
*[role="none"] .zoom-in {
  animation-name: none;
}
